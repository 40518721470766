import Step, { StepType } from "../molecules/Step";
import {
  HandThumbUpIcon,
  HomeModernIcon,
  PaintBrushIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";

const Steps: StepType[] = [
  {
    title: "Get in touch",
    description: "Get in touch with us to book your repair",
    icon: PhoneIcon,
  },
  {
    title: "We come to you",
    description: "We'll come to your location to carry out the repair",
    icon: HomeModernIcon,
  },
  {
    title: "We repair",
    description: "We'll repair your car and get it looking as good as new",
    icon: PaintBrushIcon,
  },
  {
    title: "Drive away",
    description: "You can drive away with your car looking its best",
    icon: HandThumbUpIcon,
  },
];

export default function HowItWorks() {
  return (
    <section id="how-it-works" className="bg-slate-300 text-brandSecondary ">
      <div className="content text-center items-center content-center ">
        <h3 className="text-4xl md:text-5xl my-4   ">How it works</h3>
        <ul className="grid grid-cols-1 gap-4 w-fit mx-auto my-12">
          {Steps.map((step, index) => (
            <li key={index} className="step flex items-stretch md:my-2">
              <div className="flex-1 flex flex-col justify-between h-full">
                <Step
                  title={step.title}
                  description={step.description}
                  icon={step.icon}
                />
              </div>
            </li>
          ))}
        </ul>

        <button className="btn w-3/4 md:w-1/3">
          <a href="tel:07495795919">Call now</a>
        </button>
      </div>
    </section>
  );
}
