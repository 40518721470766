import bgImage from "../../assets/jpg/repaired-wheel.jpg";

export default function HomeSection() {
  return (
    <section
      id="home"
      className="min-h-[75vh] relative overflow-hidden flex items-center justify-center"
    >
      <div className="overlay bg-slate-300 absolute left-0 top-0 right-0 bottom-0 z-30 opacity-75"></div>
      <img
        src={bgImage}
        className="absolute left-0 top-0 right-0 bottom-0 object-cover object-center w-full h-full z-20 select-none"
      />
      <div className="content z-50 text-center">
        <h1 className="text-black text-6xl md:text-7xl mb-6 md:mb-8">
          Smart Repair Specialists
        </h1>
        <p className="text-black text-2xl md:text-3xl font-semibold">
          The best in the business, right on your doorstep.
        </p>

        <button className="btn w-3/4 md:w-1/2 mt-4">
          <a href="tel:07495795919">Call now</a>
        </button>
        {/* TODO: Add trust pilot review summary */}
      </div>
    </section>
  );
}
