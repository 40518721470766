export default function AboutSection() {
  return (
    <section id="about">
      <div className="content text-center md:text-left">
        <h3 className="text-4xl md:text-5xl my-4 mt-0">
          {" "}
          The Ultimate Solution{" "}
        </h3>
        {/* TODO: Replace copy */}

        <div className="">
          <p>
            Everything from scuffs to minor dent repairs, we've got you covered.
          </p>
          <p>
            Our team of experts are dedicated to providing the best service
            possible. We pride ourselves on our customer service and the quality
            of our work. We're here to help you get your car back to its former
            glory.
          </p>
          <p>
            We offer a range of services to help you get your car looking as
            good as new. Whether you need a minor dent repair or a bumper
            scratch, we've got you covered. Our team of experts are here to help
            you get your car looking its best.
          </p>
          <p>
            We use the latest technology and techniques to ensure that your car
            looks as good as new. Our team of experts are dedicated to providing
            the best service possible, and we're here to help you get your car
            back to its former glory.
          </p>
        </div>
      </div>
    </section>
  );
}
