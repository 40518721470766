export type Services = {
  title: string;
  description: string;
  icon: string;
};

const services: Services[] = [
  {
    title: "Bumper Scuffs",
    description:
      "Say goodbye to bumper scuffs and scrapes with our expert restoration solutions today!",
    icon: "",
  },
  {
    title: "Machine Polishing",
    description:
      "Enhance your car's finish with our professional machine polishing service, designed to remove fine scratches and restore a glossy, showroom-quality shine.",
    icon: "",
  },
  {
    title: "Minor Dent Repairs",
    description:
      "Seamless dent repairs - restore your car's smooth surface with expert precision.",
    icon: "",
  },
  {
    title: "Alloy Wheel Refurbs",
    description:
      "Revitalise your alloy & diamond cut wheels with our expert repair and restoration services.",
    icon: "",
  },
  {
    title: "Key Scratch Repairs",
    description:
      "Effortlessly erase key scratches with our precision repair techniques, leaving your car's paintwork flawless and protected.",
    icon: "",
  },
];

export default function ServicesSection() {
  return (
    <section id="services">
      <div className="content">
        <h3 className="text-4xl md:text-5xl my-4 text-center mt-0">Services</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {services.map((service) => (
            <div key={service.title} className="bg-white shadow-md py-12">
              <h4 className="text-4xl font-semibold text-center mb-4">
                {service.title}
              </h4>
              <p className="text-xl text-center mt-4 text-black">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
