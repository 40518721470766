import { Link } from "react-router-dom";
import { useEffect, useRef, ReactNode, useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";

interface NavbarProps {
  children?: ReactNode;
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

export default function Navbar({
  children,
  isMenuOpen,
  setIsMenuOpen,
}: NavbarProps) {
  const navRef = useRef<HTMLDivElement>(null);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (window.scrollY > 150) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  return (
    <nav ref={navRef} className="z-[100] fixed top-0 w-full">
      <div
        className={`main-nav p-3  transition-colors duration-300 ${
          isScrolled ? "bg-brandBackground text-white" : "bg-none text-black"
        } ${isMenuOpen ? "text-white" : "text-black"}`}
      >
        <ul className="flex justify-between p-2 px-5 align-middle items-center max-w-[1600px] mx-auto text-xl">
          {/* LEFT */}
          <div className="justify-between flex gap-10">
            <li className="brand font-bold cursor-pointer">
              <Link to="#home">Ultimate Spray Solutions {isScrolled}</Link>
            </li>
            <li className="hidden md:flex gap-5 font-bold cursor-pointer">
              <Link to="#about">About</Link>
            </li>
            <li className="hidden md:flex gap-5 font-bold cursor-pointer">
              <Link to="#services">Services</Link>
            </li>
            <li className="hidden md:flex gap-5 font-bold cursor-pointer">
              <Link to="#contact">Contact</Link>
            </li>
          </div>
          {/* RIGHT */}
          <div className="justify-end gap-6 float-right flex md:hidden">
            <li
              className="align-middle m-auto"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Bars3Icon className="h-6 w-6" />
            </li>
          </div>
        </ul>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-brandBackground p-3 text-[#f0ffff] rounded-b-xl md:rounded-b-none">
          <ul className="flex flex-col gap-4 ml-4 py-4">
            <li className="font-bold cursor-pointer">
              <Link to="#home">Home</Link>
            </li>
            <li className="font-bold cursor-pointer">
              <Link to="#about">About</Link>
            </li>
            <li className="font-bold cursor-pointer">
              <Link to="#contact">Contact</Link>
            </li>
          </ul>
          {children}
        </div>
      )}
    </nav>
  );
}
