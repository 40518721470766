import React from "react";

export type StepType = {
  title: string;
  description: string;
  icon: React.ElementType;
};

export default function Step({ title, description, icon: Icon }: StepType) {
  return (
    <div
      className="step
      flex flex-col items-center justify-center
      text-center
      bg-blue-100
      border-blue-200 border-2 rounded-lg
      h-full
      px-4
      py-12
      "
    >
      <div className="text mt-4">
        <h4 className="text-4xl font-semibold text-center mb-4">{title}</h4>
        <div className="flex justify-center my-4">
          <div className="rounded-full bg-black p-4">
            <Icon className="size-6 text-blue-500" />
          </div>
        </div>
        <p className="text-2xl">{description}</p>
      </div>
    </div>
  );
}
