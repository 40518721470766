import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import useSmoothScroll from "./hooks/useSmoothScroll";

/**
 * @note daiayui is ui framework of choice. https://daisyui.com/
 */
import "./App.css";
import HomePage from "./pages/Home";
import DefaultLayout from "./components/organisms/layouts/default";

function App() {
  return (
    <Router>
      <SmoothScrollWrapper>
        <div className="App">
          <DefaultLayout>
            <HomePage />
          </DefaultLayout>
        </div>
      </SmoothScrollWrapper>
    </Router>
  );
}

function SmoothScrollWrapper({ children }: { children: React.ReactNode }) {
  useSmoothScroll();
  return <>{children}</>;
}

export default App;
