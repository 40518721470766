import AboutSection from "../components/organisms/AboutSection";
import ContactSection from "../components/organisms/ContactSection";
import HomeSection from "../components/organisms/HomeSection";
import HowItWorks from "../components/organisms/HowItWorks";
import ServicesSection from "../components/organisms/ServicesSection";
import WhyChoose from "../components/organisms/WhyChoose";

export default function HomePage() {
  return (
    <div className="">
      <HomeSection />
      <AboutSection />
      <ServicesSection />
      <HowItWorks />
      <WhyChoose />
      <ContactSection />
    </div>
  );
}
