export default function ContactSection() {
  return (
    <section id="contact" className="text-center md:text-left">
      <div className="content">
        <h3 className="text-4xl md:text-5xl my-4 mt-0">Contact A Specialist</h3>
        <p>Get in touch with us today to book your repair on your doorstep. </p>
        <p>
          Call us on{" "}
          <a href="tel:07495795919" className="underline">
            07495 795 919
          </a>
        </p>
        <p>
          Email us at{" "}
          <a
            href="mailto:ultimatespraysolutionltd@gmail.com"
            className="underline"
          >
            ultimatespraysolutionltd@gmail.com
          </a>
        </p>
      </div>
    </section>
  );
}
