import { useState } from "react";
import { ReactNode } from "react";
import Navbar from "../../molecules/Navbar";

interface DefaultLayoutProps {
  children: ReactNode;
}

export default function DefaultLayout({ children }: DefaultLayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div>
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main
        className={`transition-all duration-300 ${isMenuOpen ? "mt-48" : ""}`}
      >
        {children}
      </main>
    </div>
  );
}
